
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ServiceTypesModel } from '@/core/models/ServiceTypesModel'
import { namespace } from 'vuex-class'
const ServiceTypesStore = namespace('ServiceTypesStore')

@Component({
  name: 'ImplementerPrices',
})
export default class ImplementerPrices extends Vue {
  @Prop()
  readonly services: any

  paymentType = {
    full: 'сдельная',
    hour: 'почасовая',
  }

  @ServiceTypesStore.Getter
  public serviceTypesList!: ServiceTypesModel

  @ServiceTypesStore.Action
  private getServiceTypes!: () => Promise<ServiceTypesModel>

  public async created(): void {
    await this.getServiceTypes()
  }

  public getServiceType(id: number): string {
    const service = this.serviceTypesList.find(
      (serviceData) => serviceData.id === id
    )
    return service?.name
  }
}
