
import { Component, Vue, Ref } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const ImplementerStore = namespace('ImplementerStore')
// const UserStore = namespace('UserStore')
// const StatististicsStore = namespace('StatististicsStore')
import { ImplementerProfileModel } from '@/store/modules/implementer.module'
import { v4 as uuidv4 } from 'uuid'
import { TippyComponent } from 'vue-tippy/dist/vue-tippy.esm'

import Content from '@/layouts/components/Content.vue'
import BaseTitle from '@/components/base/BaseTitle.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import StatisticsCharts from '@/modules/components/StatisticsCharts.vue'
// import NoBillingPlanWarning from './components/NoBillingPlanWarning.vue'
// import Room from './components/Room.vue'
// import RoomForm from './components/RoomForm.vue'
// import AddressForm from '@/modules/components/AddressForm.vue'
// import IntegrationForm from './components/IntegrationForm.vue'
// import ConditionForm from './components/ConditionForm.vue'
// import Modal from '@/components/Modal.vue'
// import BaseAddEntityBlock from '@/components/base/BaseAddEntityBlock.vue'
// import { UserModel } from '@/core/models/UserModel'
import ImplementerSlider from './components/ImplementerSlider.vue'
import ImplementerCard from './components/ImplementerCard.vue'
import ImplementerDescription from './components/ImplementerDescription.vue'
import ImplementerPrices from './components/ImplementerPrices.vue'
import ImplementerWorkExamples from './components/ImplementerWorkExamples.vue'
import ImplementerReview from './components/ImplementerReview.vue'
import ImplementerProfileForm from './components/ImplementerProfileForm/ImplementerProfileForm.vue'

import breakpoints from '@/plugins/breakpoints'

@Component({
  name: 'ImplementerPage',
  components: {
    Content,
    BaseTitle,
    BaseButton,
    BaseIcon,
    StatisticsCharts,
    TippyComponent,
    ImplementerSlider,
    ImplementerCard,
    ImplementerDescription,
    ImplementerPrices,
    ImplementerWorkExamples,
    ImplementerReview,
    ImplementerProfileForm,
  },
  metaInfo: {
    title: 'Список помещений',
  },
})
export default class ImplementerPage extends Vue {
  // @Ref() readonly roomForm!: RoomForm
  // updated = false
  // createPlaceSuccess = false
  isActiveRoom = true
  isVisibleProfileForm = false

  public onClickEditButton(): void {
    this.isVisibleProfileForm = !this.isVisibleProfileForm
  }

  public onClickSave(): void {
    this.onClickEditButton()
  }
  // createPlaceSuccessMessage: string | null = ''
  // openForm = false
  // roomFormKey = uuidv4()
  // isAddressFormVisible = false
  // isIntegrationFormVisible = false
  // isConditionFormVisible = false
  // conditionForEdit = null
  // conditionForEditIndex = null
  // breakpoints = breakpoints
  // @UserStore.Getter
  // public currentUser!: UserModel
  // @StatististicsStore.Getter
  // public statististicsList!: any
  @ImplementerStore.Getter
  public profileInfo!: ImplementerProfileModel
  @ImplementerStore.Action
  private getImplementerProfileModel!: () => Promise<ImplementerProfileModel>
  // @StatististicsStore.Action
  // private getStatististics!: () => Promise<any>
  // get isMobile(): boolean {
  //   return breakpoints.width <= 768
  // }
  // get addAvailable(): boolean {
  //   return (
  //     !!this.currentUser?.billing_plan?.current ||
  //     !!this.currentUser?.billing_plan?.next
  //   )
  // }
  // get activeBillingPlan(): boolean {
  //   return !!this.currentUser?.billing_plan?.current
  // }
  async created(): void {
    await this.getImplementerProfileModel()
    if (!this.profileInfo.id) {
      this.isVisibleProfileForm = true
    }
    console.log('this.profileInfo', this.profileInfo)
  }
  // placeCreated(message: string | null): void {
  //   this.openForm = false
  //   this.getRooms()
  //   this.getStatististics()
  //   this.createPlaceSuccess = true
  //   this.createPlaceSuccessMessage = message
  // }
  // addRoom(): void {
  //   this.roomFormKey = uuidv4()
  //   this.openForm = true
  //   this.isAddressFormVisible = false
  //   this.isIntegrationFormVisible = false
  //   this.isConditionFormVisible = false
  //   this.conditionForEditIndex = null
  // }
  // backToForm(): void {
  //   this.openForm = true
  //   this.isAddressFormVisible = false
  //   this.isIntegrationFormVisible = false
  //   this.isConditionFormVisible = false
  //   this.conditionForEditIndex = null
  // }
  // async editRoom(id: number): Promise<void> {
  //   // this.roomFormKey = uuidv4()
  //   // if (this.roomForm) {
  //   //   this.roomForm.resetModel()
  //   //   await this.roomForm.fetchModel(id)
  //   //   this.openForm = true
  //   // }
  // }
  // showAddressForm(): void {
  //   this.isAddressFormVisible = true
  //   this.openForm = false
  //   this.isIntegrationFormVisible = false
  // }
  // showIntegrationForm(): void {
  //   this.isAddressFormVisible = false
  //   this.openForm = false
  //   this.isIntegrationFormVisible = true
  // }
  // showConditionForm(): void {
  //   this.conditionForEdit = null
  //   this.conditionForEditIndex = null
  //   this.isConditionFormVisible = true
  //   this.openForm = false
  // }
  // editCondition(payload: any): void {
  //   this.conditionForEdit = payload.condition
  //   this.conditionForEditIndex = payload.index
  //   this.isConditionFormVisible = true
  //   this.openForm = false
  // }
}
