
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import BaseButton from '@/components/base/BaseButton.vue'

@Component({
  name: 'ImplementerReview',
  components: {
    BaseRating: () => import('@/components/base/BaseRating'),
    BaseButton,
  },
})
export default class ImplementerReview extends Vue {
  @Prop({ default: () => [] })
  readonly reviews: any[]

  showReviewForm = false
  showGratitude = false
  name = ''
  reviewText = ''
  rating = ''
  resMessage = ''
  tryToSendForm = false
  nameBtnReview = 'Добавить отзыв'

  isAuthenticated = true

  @Watch('isAuthenticated')
  onChangeIsAuthenticated(value): void {
    if (value) {
      this.onClickReviewBtn()
    }
  }

  get isDisabledSendReviewButton(): boolean {
    return !this.reviewText || this.isNameEmpty
  }
  get userName(): string {
    return ''
  }
  get isNameEmpty(): boolean {
    return !(this.name || this.userName)
  }
  get hasNameErrors(): boolean {
    return this.isNameEmpty
  }
  get hasValidationErrors(): boolean {
    return this.isNameEmpty || !this.rating
  }
  get nameErrorText(): string {
    if (this.isNameEmpty) {
      return 'Поле не заполнено'
    }
    return ''
  }

  public mounted(): void {
    if (this.reviews.length === 0) {
      this.nameBtnReview = 'Оставить отзыв'
    }
  }

  public onClickReviewPopup(): void {
    this.$root.$emit('login-popup', this.$route.fullPath)
  }

  public onClickReviewBtn(): void {
    this.showReviewForm = true
  }

  public async onSendReview(event: any): void {
    event.preventDefault()

    if (this.hasValidationErrors) {
      this.tryToSendForm = true

      return
    }

    this.showReviewForm = false
    this.showGratitude = true

    // const res = await sendReview({
    //   place_id: this.roomId,
    //   name: this.name || this.userName,
    //   rating: Number(this.rating),
    //   comment: this.reviewText,
    // })

    // this.resMessage = res.message[0]
  }
}
