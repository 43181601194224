
import { Component, Vue, Prop } from 'vue-property-decorator'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseCheckbox from '@/components/forms/BaseCheckbox.vue'
import Badge from '@/components/Badge.vue'

@Component({
  name: 'ImplementerCard',
  components: {
    ImplementerBreadcrumbs: () => import('./ImplementerBreadcrumbs'),
    BaseRating: () => import('@/components/base/BaseRating'),
    BaseButton,
    BaseIcon,
    BaseCheckbox,
    Badge,
  },
})
export default class ImplementerPage extends Vue {
  @Prop()
  implementerData!: any

  isShowPopup = false
}
